import { useAlignment } from "@/api/alignment/hooks";
import { useProteinSearch } from "@/api/protein_search/hooks";
import {
  CDSId,
  ProteinSearchOutputResult,
  ProteinSearchQueryParams,
  UNANNOTATED_CLIP_LABEL,
} from "@/api/protein_search/schemas";
import { UMAP } from "@/components/UMAP/UMAP";
import { cn } from "@/utils/strings";

import { LoaderCircleIcon } from "lucide-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ActiveCard } from "./ActiveCard";
import { GaiaAgentDisplay } from "./GaiaAgentDisplay";
import { PaginatedAccordion } from "./PaginatedAccordion";

export const SearchResults = ({
  sequence,
  className,
}: {
  sequence: string;
  className?: string;
}) => {
  const [searchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams.entries());

  const { mockEmbedding, contextBefore, contextAfter, debug } =
    ProteinSearchQueryParams.parse(searchParamsObject);

  const { proteinSearchResults, isFetchingProteinSearch, proteinSearchError } =
    useProteinSearch({
      sequence,
      mockEmbedding,
      contextBefore,
      contextAfter,
      debug,
    });
  const { alignmentData, isFetchingAlignment, alignmentError } = useAlignment({
    query: sequence,
    match_list: proteinSearchResults?.searchResults?.map(
      (record) => record.match,
    ),
  });
  //
  const [gaiaAgentPayload, setGaiaAgentPayload] =
    useState<ProteinSearchOutputResult | null>(null);
  const findAlignmentByCDSId = (cdsId: CDSId) => {
    const res =
      alignmentData?.records.find((result) => {
        return result.refCdsId.cds_shorthand === cdsId.cds_shorthand;
      }) ?? null;
    return res;
  };
  const containerClassName = cn(
    "px-8 min-h-80 flex flex-col gap-4 items-center justify-center ",
    className,
  );
  const [activeMatchCdsShorthand, setActiveMatchCdsShorthand] = useState<
    string | null
  >(null);
  const activeMatch = proteinSearchResults?.searchResults?.find(
    (x) => x.match.cdsId.cds_shorthand === activeMatchCdsShorthand,
  );

  if (isFetchingProteinSearch) {
    return (
      <div className={cn(containerClassName, "animate-spin text-noir-600")}>
        <LoaderCircleIcon className="h-12 w-12 text-noir-400" />
      </div>
    );
  }
  if (proteinSearchError) {
    return (
      <div className={cn(containerClassName, "text-center text-red-700")}>
        An internal error occured during protein search.
      </div>
    );
  }
  if (!proteinSearchResults) {
    return (
      <div className={cn(containerClassName, "text-center text-noir-600")}>
        No results found for query
      </div>
    );
  }
  const lengths = proteinSearchResults.searchResults.flatMap((searchResult) =>
    searchResult.contig.map((record) => record.cdsId.end - record.cdsId.start),
  );
  const maxContextLength = Math.max(...lengths);

  return (
    <div className={className}>
      <Banner />
      <section className="grid grid-cols-1 gap-2 md:grid-cols-12">
        <UMAP
          className={"md:col-span-6"}
          queryUMAPDatum={proteinSearchResults.query.umap_datum}
          matchUMAPData={proteinSearchResults.searchResults.map(
            (x) => x.match_umap_datum,
          )}
          alignmentData={alignmentData?.records}
          isFetchingAlignment={isFetchingAlignment}
          alignmentError={alignmentError}
          onClick={(cds_shorthand) => {
            setActiveMatchCdsShorthand(cds_shorthand);
          }}
        />
        {activeMatch ? (
          <ActiveCard
            header={
              <div className="flex flex-col justify-start ">
                <div className="flex items-start gap-2 font-semibold">
                  {activeMatch.match.clipAnnotation?.clipDescription ??
                    UNANNOTATED_CLIP_LABEL}{" "}
                  <p className="text-noir-400">
                    {activeMatch.score.toFixed(3)}
                  </p>
                </div>
                <p className="text-left text-xs font-light">
                  {/* should match AccordionTrigger in PaginatedAccordion.tsx */}
                  {`p__${activeMatch.taxonomy.phylum}; s__${activeMatch.taxonomy.species}`}
                </p>
                {/* needed to match the spacing created by the description subtitle in the UMAP component */}
                <p className="pre text-left text-xs font-light opacity-0">
                  Spacer
                </p>
              </div>
            }
            className={"md:col-span-6"}
            match={activeMatch.match}
            contig={activeMatch.contig}
            alignment={findAlignmentByCDSId(activeMatch.match.cdsId)}
            isFetchingAlignment={isFetchingAlignment}
            alignmentError={alignmentError}
            size="mini"
            maxContextLength={maxContextLength}
          />
        ) : (
          <ActiveCard
            isQuery={true}
            maxContextLength={maxContextLength}
            header={
              <div className="flex flex-col justify-start ">
                <div className="flex items-start gap-2 font-semibold">
                  Query
                </div>
                <p className="text-left text-xs font-light">Your submission</p>
                {/* needed to match the spacing created by the description subtitle in the UMAP component */}
                <p className="pre text-left text-xs font-light opacity-0">
                  Spacer
                </p>
              </div>
            }
            className={"md:col-span-6"}
            match={proteinSearchResults.query.record}
            setGaiaAgentPayload={() =>
              // Currently, we always send the first/top search result
              setGaiaAgentPayload(proteinSearchResults.searchResults[0])
            }
            alignment={undefined}
            isFetchingAlignment={false}
            alignmentError={null}
            size="mini"
          />
        )}
      </section>

      {gaiaAgentPayload && (
        <section className="mt-4 rounded-md border border-brand-600 bg-white !py-2 px-4 ">
          <GaiaAgentDisplay
            agentPayload={gaiaAgentPayload}
            query_seq={sequence}
          />
        </section>
      )}

      <div className="pt-4">
        <PaginatedAccordion proteinSearchResults={proteinSearchResults} />
      </div>
    </div>
  );
};

export const Banner = () => {
  return (
    <div className="my-2 hidden rounded-md border border-brand-600 bg-white py-4 text-center text-brand-600">
      <h4> Gaia Agent is now Available! </h4>
      <h5>
        {" "}
        Predict functions of query sequence using co-occurrence, HMMs, and
        structure.{" "}
      </h5>
    </div>
  );
};
