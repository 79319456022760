import { useMutation, useQuery } from "@tanstack/react-query";

import { useAuth, useToken } from "@/api/auth/hooks";
import { useAnalyticsEvent } from "@/utils/analytics";
import { gaiaAPI } from "@/utils/wretch";
import { ProteinSearchOutputResult } from "../protein_search/schemas";
import { convertProteinSearchOutputToInput } from "../protein_search/utils";
import {
  GaiaAgentRatingRequest,
  GaiaAgentRatingRequestSchema,
  GaiaAgentRatingResponseSchema,
  GaiaAgentRequestSchema,
  GaiaAgentResponseSchema,
} from "./schemas";

export const useGaiaAgent = (
  payload: ProteinSearchOutputResult | null,
  pdbStructure?: string,
) => {
  const { user, token } = useAuth();
  const { trackCustomEvent } = useAnalyticsEvent();
  const queryFn = async () => {
    if (payload === null) {
      throw Error("Payload is null");
    }
    const req = GaiaAgentRequestSchema.parse({
      // need to convert back into the backend version of the results, because that's what the API expects
      proteinRecord: convertProteinSearchOutputToInput(payload),
      pdbStructure: pdbStructure ?? null,
    });
    trackCustomEvent({
      eventName: "gaia_agent_request",
      params: {
        cds_shorthand: payload.match.cdsId.cds_shorthand,
        user_email: user?.emailAddress ?? "unknown user",
      },
    });

    const res = await gaiaAPI
      .auth(`Bearer ${token}`)
      .url("/gaia_agent/predictions/")
      .post(req)
      .json();

    const parsed = GaiaAgentResponseSchema.safeParse(res);
    if (!parsed.success) {
      console.error("Frontend failed to parse api response");
      console.error(parsed.error);
      throw Error(
        "Frontend failed to parse api response. Make sure api response matches GaiaAgentResponseSchema",
      );
    }
    return parsed.data;
  };

  const { data, isFetching, error } = useQuery({
    queryKey: ["gaiaAgent", payload?.match.cdsId.cds_shorthand],
    queryFn: queryFn,
    enabled: !!payload,
  });

  return {
    gaiaAgentResponse: data,
    isFetchingGaiaAgentResponse: isFetching,
    gaiaAgentResponseError: error,
  };
};

export const useGaiaAgentRating = () => {
  const token = useToken();

  return useMutation({
    mutationFn: async (rating_payload: GaiaAgentRatingRequest) => {
      const payload = GaiaAgentRatingRequestSchema.parse(rating_payload);

      const res = await gaiaAPI
        .auth(`Bearer ${token}`)
        .url(`/gaia_agent/predictions/${payload.predictionId}/reactions`)
        .post(payload)
        .json();

      const parsed = GaiaAgentRatingResponseSchema.safeParse(res);
      if (!parsed.success) {
        console.error("Frontend failed to parse api response");
        console.error(parsed.error);
        throw Error(
          "Frontend failed to parse api response. Make sure api response matches GaiaAgentRatingResponseSchema",
        );
      }
      return parsed.data;
    },
  });
};
