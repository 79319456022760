import { useToken } from "@/api/auth/hooks";
import { isSeqTooLong } from "@/utils/strings";
import { gaiaAPI } from "@/utils/wretch";
import { useQuery } from "@tanstack/react-query";
import {
  FoldingRequestSchema,
  FoldingResponseSchema,
  ProteinSchema,
} from "./schemas";
import { WretchError } from "wretch/types";

// externalizing so we can prefetch this query in useProteinSearch
export const foldingQueryFn = async (protein_seq: string, token: string) => {
  if (!ProteinSchema.safeParse(protein_seq)) {
    throw new Error("Invalid protein sequence");
  }
  const query = FoldingRequestSchema.parse({ protein_seq });
  const res = await gaiaAPI
    .auth(`Bearer ${token}`)
    .query(query)
    .catcher(422, () => {
      throw {
        name: "Unprocessable Entity",
        message:
          "Protein folding failed. The protein sequence may be too long.",
      };
    })
    .get("/folding")
    .json();

  const parsed = FoldingResponseSchema.parse(res);
  return parsed;
};
export const useFolding = ({ protein_seq }: { protein_seq: string | null }) => {
  const token = useToken();

  const queryFn = async () => {
    if (!protein_seq) {
      return;
    }
    if (!token) {
      throw new Error("No token found");
    }
    return foldingQueryFn(protein_seq, token);
  };
  const { data, isFetching, error } = useQuery({
    queryKey: ["folding", protein_seq],
    enabled: !!protein_seq && !isSeqTooLong(protein_seq),
    queryFn: queryFn,
    // we want to cache the folding result so we don't refold things on component mount
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
  });

  return {
    foldingData: data,
    isFetchingFolding: isFetching,
    foldingError: error as WretchError | null,
  };
};
