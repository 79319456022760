import { useAuth } from "@/api/auth/hooks";
import { queryClient } from "@/providers/tanstack_query";
import { useAnalyticsEvent } from "@/utils/analytics";
import { gaiaAPI } from "@/utils/wretch";
import { useQuery } from "@tanstack/react-query";
import { foldingQueryFn } from "../folding/hooks";
import {
  ProteinSearchRequestSchema,
  ProteinSearchResponseInputSchema,
} from "./schemas";
import { convertProteinSearchInputToOutput } from "./utils";

export const useProteinSearch = ({
  sequence,
  mockEmbedding,
  contextBefore,
  contextAfter,
  debug,
}: {
  // See `ProteinSearchRequest` in backend/models.py for additional details on these parameters.
  sequence: string;
  mockEmbedding?: boolean;
  contextBefore?: number;
  contextAfter?: number;
  debug?: boolean;
}) => {
  const { user, token } = useAuth();
  const { trackCustomEvent } = useAnalyticsEvent();
  const queryFn = async () => {
    if (!token) {
      throw new Error("No token found");
    }
    queryClient.prefetchQuery({
      queryKey: ["folding", sequence],
      queryFn: () => foldingQueryFn(sequence, token),
    });
    const payload = ProteinSearchRequestSchema.parse({
      sequence,
      mockEmbedding,
      contextBefore,
      contextAfter,
      maxResults: 100,
      debug,
    });

    trackCustomEvent({
      eventName: "protein_search_request",
      params: {
        sequence,
        user_email: user?.emailAddress ?? "unknown user",
      },
    });

    const res = await gaiaAPI
      .auth(`Bearer ${token}`)
      .url("/protein_search")
      .query(payload)
      .get()
      .json();

    const parsed = ProteinSearchResponseInputSchema.safeParse(res);
    if (!parsed.success) {
      console.error("Frontend failed to parse api response");
      console.error(parsed.error);
      throw Error(
        "Frontend failed to parse api response. Make sure api response matches ProteinSearchResponseInputSchema",
      );
    }
    const output = convertProteinSearchInputToOutput(parsed.data, sequence);
    return output;
  };

  const { data, isFetching, error } = useQuery({
    queryKey: [
      "protein_search_data",
      sequence,
      mockEmbedding,
      contextBefore,
      contextAfter,
      debug,
    ],
    queryFn: queryFn,
  });

  return {
    proteinSearchResults: data,
    isFetchingProteinSearch: isFetching,
    proteinSearchError: error as Error | null,
  };
};
