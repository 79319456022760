/**
 * Conditionally apply class names in React components
 * based on predicates, making it easier to manage dynamic styling.
 *
 * // Basic usage
 * const className = cn("border", isActive && "border-red-500", isDisabled && "border-gray-500");
 * // Result: "border border-red-500" (if isActive is true, isDisabled is false)
 */
export const cn = (...classes: (string | boolean | undefined)[]): string => {
  return classes.filter(Boolean).join(" ");
};

export const ALLOWED_RESIDUES = [
  "A",
  "C",
  "G",
  "T",
  "D",
  "E",
  "F",
  "H",
  "I",
  "K",
  "L",
  "M",
  "N",
  "P",
  "Q",
  "R",
  "S",
  "V",
  "W",
  "X",
  "Y",
];

export const onlyHasDNA = (seq: string | null) => {
  return seq
    ? seq.split("").every((char) => ["A", "C", "G", "T"].includes(char))
    : false;
};

export const isSeqTooLong = (seq: string | null) => {
  return seq ? seq.length > 15000 : false;
};

export const doesSequenceHaveInvalidChars = (seq: string | null) => {
  if (!seq) {
    return false;
  } else {
    return !seq.split("").every((char) => ALLOWED_RESIDUES.includes(char));
  }
};
