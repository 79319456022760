import {
  CDSId,
  ProteinRecord,
  ProteinSearchInputResult,
  ProteinSearchOutputResult,
  ProteinSearchResponseInput,
  ProteinSearchResponseOutput,
  Taxonomy,
} from "./schemas";

export const convertProteinSearchInputToOutput = (
  input: ProteinSearchResponseInput,
  querySequence: string,
): ProteinSearchResponseOutput => {
  return {
    query: {
      record: {
        sequence: querySequence,
        cdsId: {
          sample_id: "user_query_sample_id",
          contig_id: "user_query_contig_id",
          elem_type: "unknown",
          cds_shorthand: "user_query_cds_shorthand",
          strand: "forward",
          start: 1,
          end: querySequence.length,
        },
        operonPrediction: null,
        centroidId: {
          sample_id: "user_query_centroid_sample_id",
          contig_id: "user_query_centroid_contig_id",
          elem_type: "unknown",
          cds_shorthand: "user_query_centroid_cds_shorthand",
          strand: "forward",
          start: 1,
          end: querySequence.length,
        },
        centroidSequence: "user_query_centroid_sequence",
        clipAnnotation: null,
      },
      umap_datum: {
        x: input.data.query.umapData.x,
        y: input.data.query.umapData.y,
        cos_sim_score: input.data.query.umapData.cos_sim_score,
        cdsId: "query",
        cos_sim_color: input.data.query.umapData.color,
        clipAnnotation: null,
      },
    },
    searchResults: input.data.matches.map((match) => {
      const matchProtein = match.contig[match.matchIndex];
      return {
        score: match.score,
        match_umap_datum: {
          x: match.matchUmapData.x,
          y: match.matchUmapData.y,
          cos_sim_score: match.matchUmapData.cos_sim_score,
          cdsId: matchProtein.cdsId,
          cos_sim_color: match.matchUmapData.color,
          clipAnnotation: matchProtein.clipAnnotation,
        },
        taxonomy: match.matchTaxonomy,
        match: {
          cdsId: matchProtein.cdsId,
          centroidId: matchProtein.centroidId,
          centroidSequence: matchProtein.centroidSequence,
          clipAnnotation: matchProtein.clipAnnotation,
          sequence: matchProtein.sequence,
          clipData: matchProtein.clipAnnotation,
          operonPrediction: matchProtein.operonPrediction,
        },
        match_index: match.matchIndex,
        contig: match.contig
          .filter((_, i) => i !== match.matchIndex)
          .map((protein) => ({
            cdsId: protein.cdsId,
            centroidSequence: protein.centroidSequence,
            centroidId: protein.centroidId,
            sequence: protein.sequence,
            clipAnnotation: protein.clipAnnotation,
            operonPrediction: protein.operonPrediction,
          })),
      };
    }),
  };
};

export const taxonomyToLinageString = (data: Taxonomy) => {
  const capitalized = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // capitalize each part of the lineage
  const { domain, phylum, class_, order, family, genus, species } = {
    domain: capitalized(data.domain),
    phylum: capitalized(data.phylum),
    class_: capitalized(data.class_), // class_ to avoid conflict with class keyword
    order: capitalized(data.order),
    family: capitalized(data.family),
    genus: capitalized(data.genus),
    species: capitalized(data.species),
  };

  const domainStr = `d__${domain}`;
  const phylumStr = `p__${phylum}`;
  const classStr = `c__${class_}`;
  const orderStr = `o__${order}`;
  const familyStr = `f__${family}`;
  const genusStr = `g__${genus}`;
  const speciesStr = `s__${species}`;
  return [
    domainStr,
    phylumStr,
    classStr,
    orderStr,
    familyStr,
    genusStr,
    speciesStr,
  ].join("; ");
};
export const cdsIdToString = (cdsId: CDSId): string => {
  const { sample_id, contig_id, elem_type, cds_shorthand, strand, start, end } =
    cdsId;

  return `${sample_id}|${contig_id}|${elem_type}|${cds_shorthand}|${strand}|${start}:${end}`;
};

export const getContextSizeMetrics = (
  records: ProteinRecord[],
): {
  start: number;
  end: number;
  length: number;
} => {
  const minStart = Math.min(...records.map((record) => record.cdsId.start));
  const maxEnd = Math.max(...records.map((record) => record.cdsId.end));
  return {
    start: minStart,
    end: maxEnd,
    length: maxEnd - minStart,
  };
};

export const convertProteinSearchOutputToInput = (
  output: ProteinSearchOutputResult,
): ProteinSearchInputResult => {
  const contigWithMatch = [
    ...output.contig.slice(0, output.match_index),
    output.match,
    ...output.contig.slice(output.match_index),
  ].filter(Boolean); // remove any undefined values, which can happen if the match_index is the last index
  return {
    score: output.score,
    matchIndex: output.match_index,
    matchUmapData: {
      x: output.match_umap_datum.x,
      y: output.match_umap_datum.y,
      cos_sim_score: output.match_umap_datum.cos_sim_score,
      color: output.match_umap_datum.cos_sim_color,
    },
    matchTaxonomy: output.taxonomy,
    contig: contigWithMatch,
  };
};
