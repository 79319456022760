import { AuthContext } from "@/providers/auth/AuthContext";
import { gaiaAuth } from "@/utils/wretch";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useToken = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  if (token === null) {
    alert(
      "You must be logged in to access this page. Redirecting to login page...",
    );
    navigate("/login");
  }
  return token;
};

const LoginStateSchema = z.union([
  z.literal("token_null"),
  z.literal("token_expired"),
  z.literal("token_valid"),
]);

export const useLoginState = () => {
  const { token } = useAuth();

  const queryFn = async () => {
    if (token === null) {
      return LoginStateSchema.parse("token_null");
    }

    const res = await gaiaAuth
      .auth(`Bearer ${token}`)
      .url("/me")
      .get()
      .unauthorized(() => {
        return null;
      })
      .json();
    const output = z.object({ email_address: z.string() }).safeParse(res);
    if (!output.success) {
      console.warn(output.error);
      console.log("user has expired token");
      return LoginStateSchema.parse("token_expired");
    }
    console.log("user has valid token");
    return LoginStateSchema.parse("token_valid");
  };

  const { data, isFetching, error } = useQuery({
    queryKey: ["me"],
    queryFn: queryFn,
  });

  return {
    loginState: data,
    isFetchingLoginState: isFetching,
    loginStateError: error as Error | null,
  };
};
