import { useLoginState } from "@/api/auth/hooks";
import { useWarmUp } from "@/api/warm_up/hooks";
import { ExampleSeqsGrid } from "@/components/ExampleSeqsGrid";
import { Shell } from "@/components/Shell/Shell";
import { Input } from "@/components/ui/input";
import {
  ALLOWED_RESIDUES,
  doesSequenceHaveInvalidChars,
  isSeqTooLong,
  onlyHasDNA,
} from "@/utils/strings";
import { SequenceViewer } from "@nitro-bio/sequence-viewers";
import { SearchIcon } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  const navigate = useNavigate();
  const { warmUpData, isFetchingWarmUp, warmUpError } = useWarmUp();
  if (isFetchingWarmUp) {
    console.info("Warming up endpoints...");
  }
  if (warmUpError) {
    console.error(warmUpError);
  }
  if (warmUpData) {
    switch (warmUpData.status) {
      case "ok":
        console.info("Endpoints are ready!");
        break;
      case "warmup_disabled":
        console.warn("Endpoint warmup disabled");
        break;
      default:
        console.error("Unknown status received", warmUpData.status);
    }
  }
  const [sequence, setSequence] = useState<string | null>(null);
  const { loginState } = useLoginState();
  console.log({ loginState });
  if (loginState === "token_expired") {
    console.error("Token expired, redirecting to login");
    navigate("/login");
  }

  const onSubmit = () => {
    if (
      isSeqTooLong(sequence) ||
      doesSequenceHaveInvalidChars(sequence) ||
      onlyHasDNA(sequence)
    ) {
      alert("Invalid sequence");
    } else {
      navigate(`/search/${sequence}`);
    }
  };
  return (
    <Shell noSearch noLogo className="!bg-noir-200">
      <div className="flex min-h-screen flex-col items-center justify-center bg-noir-200">
        <form onSubmit={onSubmit}>
          <SequenceForm sequence={sequence} setSequence={setSequence} />
        </form>
      </div>
    </Shell>
  );
};

const SequenceForm = ({
  sequence,
  setSequence,
}: {
  sequence: string | null;
  setSequence: (next: string | null) => void;
}) => {
  return (
    <>
      <div className="flex flex-col">
        <header className="flex flex-col items-center gap-6">
          <h4 className="mx-auto font-sans text-7xl text-brand-600">Gaia</h4>
        </header>

        <div className="relative mx-auto flex w-full w-full max-w-md items-center pt-8">
          <Input
            className=" w-full !rounded-full bg-white pl-10"
            value={sequence ?? ""}
            onChange={(e) =>
              // upcase and remove whitespace
              setSequence(e.target.value.toUpperCase().replace(/\s/g, ""))
            }
            placeholder="Enter your sequence here"
          />
          <button className="absolute left-4" type="submit">
            <SearchIcon className="h-4 w-4 rounded-full text-black text-noir-600" />
          </button>
        </div>
        <p className="mx-auto my-4  max-w-md px-2 text-left text-center text-xs text-noir-600">
          Gaia is an embedding-based search engine for sequences. Enter your
          protein sequence above to search the protein universe.
        </p>
        {onlyHasDNA(sequence) && (
          <p className="mx-auto mt-8 rounded-md bg-red-50 px-2 py-1 text-red-600">
            Sequence is all nucleotides - Gaia works on protein sequences
          </p>
        )}
        {isSeqTooLong(sequence) && (
          <p className="mx-auto mt-8 rounded-md bg-red-50 px-2 py-1 text-red-600">
            Sequence must be shorter than 15000 residues
          </p>
        )}
        {doesSequenceHaveInvalidChars(sequence) && (
          <p className="mx-auto mt-8 rounded-md bg-red-50 px-2 py-1 text-red-600">
            Sequence must contain only valid amino acids
          </p>
        )}
        {!sequence && <ExampleSeqsGrid className="mx-auto mb-8 max-w-3xl" />}

        {sequence && (
          <div className="mx-auto mt-8 h-80 w-fit overflow-y-auto">
            <SequenceViewer
              containerClassName="max-w-md mx-auto px-4 "
              sequences={[sequence]}
              annotations={[]}
              charClassName={charClassName}
              selection={null}
              noValidate
            />
          </div>
        )}
      </div>
    </>
  );
};
const charClassName = ({ base: { base } }: { base: { base: string } }) => {
  if (ALLOWED_RESIDUES.includes(base)) {
    return "text-noir-500 text-xs";
  } else {
    return "text-red-500 text-xs";
  }
};
