import { useGaiaAgent, useGaiaAgentRating } from "@/api/gaia_agent/hooks";
import { ProteinSearchOutputResult } from "@/api/protein_search/schemas";
import { cn } from "@/utils/strings";
import { LoaderCircleIcon, SparkleIcon } from "lucide-react";
import { useState } from "react";
import { Button } from "../ui/button/button";
import { useFolding } from "@/api/folding/hooks";
import { CheckIcon } from "@radix-ui/react-icons";

export const GaiaAgentDisplay = ({
  agentPayload,
  query_seq,
  className,
}: {
  agentPayload: ProteinSearchOutputResult | null;
  query_seq: string;
  className?: string;
}) => {
  const { foldingData } = useFolding({
    protein_seq: query_seq,
  });

  const {
    gaiaAgentResponse,
    isFetchingGaiaAgentResponse,
    gaiaAgentResponseError,
  } = useGaiaAgent(agentPayload, foldingData?.protein_structure);

  if (isFetchingGaiaAgentResponse) {
    return (
      <div className="flex animate-pulse gap-1">
        Thinking...{" "}
        <LoaderCircleIcon className="my-auto h-4 w-4 animate-spin text-brand-400" />
      </div>
    );
  }
  if (gaiaAgentResponseError) {
    console.error(gaiaAgentResponseError);
    return <div>Error</div>;
  }
  if (!gaiaAgentResponse) {
    return <div>No results found</div>;
  }
  return (
    <div className={cn("flex flex-col gap-1", className)}>
      <h4 className="flex items-center gap-1 font-semibold text-brand-600">
        <SparkleIcon className="h-4 w-4" />
        Gaia Agent Annotation
      </h4>
      <h4 className="-mt-1 text-xs font-light text-noir-600">
        Gaia Agent predicts functions of query sequence using co-occurrence,
        HMMs as well as structure.
      </h4>
      <div className="flex flex-col rounded-md pb-1 pt-3 text-sm">
        <span className="">
          <strong>Short Prediction:</strong> {gaiaAgentResponse.shortPrediction}
        </span>
        <span className="mt-3">
          <strong>Long Prediction:</strong> {gaiaAgentResponse.longPrediction}
        </span>
        {gaiaAgentResponse.foldseekTicket !== null && (
          <a
            href={`https://search.foldseek.com/result/${gaiaAgentResponse.foldseekTicket}/0`}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-3 text-blue-600 hover:underline"
          >
            View Foldseek Results
          </a>
        )}
      </div>
      <RatingBar predictionId={gaiaAgentResponse.id} className="-mt-1" />
    </div>
  );
};

const RatingBar = ({
  predictionId,
  className,
}: {
  predictionId: number;
  className?: string;
}) => {
  const { mutate: submitRating, isPending } = useGaiaAgentRating();
  const [ratingResult, setRatingResult] = useState<"success" | "error" | null>(
    null,
  );
  const onClick = (reaction: "thumbs_up" | "thumbs_down") => {
    submitRating(
      { reaction, predictionId },
      {
        onSuccess: () => {
          setRatingResult("success");
        },
        onError: () => {
          setRatingResult("error");
        },
      },
    );
  };
  if (isPending) {
    return (
      <div className="text-xs text-noir-600">
        <LoaderCircleIcon className="my-auto h-4 w-4 animate-spin text-noir-400" />
      </div>
    );
  }
  if (ratingResult === "success") {
    return (
      <div className="text-xs text-noir-600">
        <CheckIcon />
      </div>
    );
  }
  if (ratingResult === "error") {
    return <div className="text-xs">Rating submission failed</div>;
  }

  return (
    <span className={cn("flex items-center text-sm text-brand-600", className)}>
      Was this helpful?
      <Button variant="ghost" size="icon" onClick={() => onClick("thumbs_up")}>
        👍
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => onClick("thumbs_down")}
      >
        👎
      </Button>
    </span>
  );
};
